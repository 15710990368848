.search-viewer {
  padding: 80px 0;
}

.mulit-output-cart-image .left-side,
.mulit-output-cart-image .right-side {
  width: 50%;
  overflow: hidden;
}
/* .mulit-output-cart-image {
  background-color: white;
} */
.mulit-output-cart-image img {
  /* height: 250px; */
  height: 300px;
  /* transition: all 0.5s ease-in-out; */
  width: 100%;
  object-fit: contain;
  padding: 0.5rem 0;
}
.mulit-output-cart-image .right-side {
  background-color: white;
}
/* .mulit-output-cart-image > div:hover img {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
} */
.borr{
  border : 1px solid red !important
}
.popup-title {
  color: #d4d4d4;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.pop-responsivechange {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .pop-responsivechange {
    position: fixed;
    left: 40px;
    right: 40px;
  }
  #style-1 {
    position: relative;
    top: 50px;
    height: 320px;
    min-width: 900px;
    width: 100%;
    /* overflow: scroll; */
  }
  .popup-title {
    font-size: 20px;
  }
}

.ep-arrow{
  visibility: hidden !important;
}
