.filter-by {
  font-weight: 400;
  color: rgb(229 229 229/ 1);
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  min-width: 100px;
}

.filter-box-new .filter-by-wrapper {
  width: calc(100% - 100px);
}
.filter-box-new {
  display: flex;
  justify-content: space-between;
}
.filter-by-wrapper select,
.filter-by-wrapper input {
  border-radius: 0 !important;
  box-shadow: none;
  outline: none;
  background-color: #121823 !important;
  color: #9c9c9cb1 !important;
  border-color: #9c9c9c61;
  height: 38px;
}

.filter-by-wrapper select {
  margin-right: 10px;
  text-transform: capitalize;
  /* width: 50%; */
}
.filter-by-wrapper select:focus,
.filter-by-wrapper input:focus {
  box-shadow: none;
  outline: none;
  border-color: #7bc9b988;
}
section.sale-viewer {
  padding: 80px 0;
}

.hot-events .title,
.sale-view-title {
  font-size: 35px;
  font-weight: 600;
  margin: auto;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.sale-view-search-input input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #9c9c9c61;
  background: #00000021;
  font-family: "Poppins", sans-serif;
  color: #7bc9ba;
  padding-left: 70px;
}
.sale-view-search-input {
  width: calc(100% - 100px);
}
.sale-view-search-input input::placeholder {
  color: #9c9c9cb1;
}

.sale-view-search-input input:focus {
  border: 1px solid #7bc9b988;
  box-shadow: none;
  outline: none;
}

.sale-view-search-input .sale-search-icon {
  position: absolute;
  top: 1px;
  left: 0;
  height: 39px;
  padding: 8px 20px;
  color: #9c9c9cb1;
  border-right: 1px solid #9c9c9c61;
}

/* search-output-cards */
.hot-event-wrapper,
.search-output-cards {
  gap: 2%;
}
.hot-event-wrapper .output-card,
.search-output-cards .output-card {
  /* width: 60%; */
  margin: auto;
  margin-bottom: 35px;
  box-shadow: rgb(86 85 85 / 20%) 0px 18px 50px -10px;
}
.hot-event-wrapper .output-card {
  width: 32%;
  margin: inherit;
  margin-bottom: 35px;
}
.search-output-cards .output-card {
  width: 100%;
}
.hot-event-wrapper .output-card:hover,
.search-output-cards .output-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.output-card .output-cart-image {
  min-width: 200px;
  overflow: hidden;
  height: 300px;
}
.hot-event-wrapper .output-card .output-cart-image {
  height: 220px;
}
.output-card:hover .output-cart-image img {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}

.output-card .output-cart-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;

  transition: all 0.5s ease-in-out;
}
.output-card .output-cart-content {
  padding: 1rem;
}
.output-cart-content .title,
.output-cart-content .title a {
  text-transform: capitalize;
  font-weight: 600;
  text-align: left;
  color: rgb(229 229 229/ 1);
  text-decoration: none;
  font-size: 20px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.output-cart-content .title {
  margin: 5px 0 10px 0;
}
.output-cart-content .title a {
  line-height: 40px;
}
.output-cart-content .title a:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
  color: #7bc9ba;
}

.output-cart-content .address {
  font-size: 15px;
  line-height: 25px;
  color: hsl(0deg 0% 90% / 46%);
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.output-cart-content .weekname {
  color: hsla(0, 0%, 90%, 0.7);
  font-size: 15px;
  line-height: 1rem;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.show-more-sales {
  margin-top: 20px;
  text-align: center;
}
.search-button,
.show-more-sales button {
  background-color: #7bc9ba;
  padding: 0.5rem 4rem;
  border: none;
  color: black;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border: 1px solid #7bc9ba;
}
.show-more-sales button:hover {
  background-color: #121823;
  color: #7bc9ba;
}
.search-button {
  padding: 0.5rem 1rem;
}
.filter-box-new .search-button {
  margin-left: 10px !important;
}
.search-button:hover {
  background-color: #121823;
  color: #7bc9ba;
}
/*  GRAPHIC */
.graphic {
  margin: 50px 0 20px 0 !important;
  /* margin: 100px 0 20px 0 !important; */
}
.graphic .heading {
  color: #d4d4d4;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}
.graphic .time-right {
  font-size: 15px;
  line-height: 25px;
  color: hsl(0deg 0% 90% / 46%);
  margin-bottom: 0;
  font-weight: 400;
  text-transform: capitalize;
}
.graphic p.recordss {
  margin: 0;
  border: 1px solid #00000085;
  display: inline-block;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  background: #7bc9ba29;
  color: #7bc9ba;
  font-weight: 500;
  margin: 10px 0 0 0;
}

/*  DISCTPIOTN */
.enevt-cards-disc button {
  padding: 0.5rem 4rem;
  border: none;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border: 1px solid #7bc9ba;
  background-color: #121823;
  color: #7bc9ba;
  width: 100%;
}
.enevt-cards-disc button:hover {
  color: black;
  background-color: #7bc9ba;
}
.enevt-cards-disc .event-cardss {
  gap: 20px;
}

.enevt-cards-disc .cart {
  padding: 0.5rem;
  background: #000000ab;
  color: #7bc9ba;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-width: 190px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.enevt-cards-disc .cart:hover {
  transform: scale(1.15);
  transition: all 0.5s ease-in-out;
}
.cart .total-price {
  font-weight: 600;
  color: rgb(229 229 229/ 1);
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 0;
}
.cart .title {
  font-size: 15px;
  margin-bottom: 15px;
}

/* ////////////// POPUP LISING */
.event-sale-listing .event-sale-detail {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  max-height: 600px;
  /* overflow-x: hidden; */
  overflow: hidden;
  /* padding: 0 2rem 1rem 2rem; */
  padding: 0.5rem 1rem 2rem 2rem;
  background-color: #060606;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  border-radius: 10px;
}
.event-sale-listing .event-sale-detail.addsuggest {
  max-height: 400px;
  box-shadow: 0px 0px 12px 3px rgba(225, 233, 235, 0.82);
}
#style-1 {
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 2rem;
  padding-right: 1rem;
}
.table > thead {
  position: sticky;
  top: -1px;
  z-index: 5;
}

.event-sale-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  backdrop-filter: blur(2px);
  background-color: #ffffff14;
  z-index: 1;
}
/* .event-sale-listing {
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
} */

.table > :not(caption) > * > * {
  text-align: center;
  border-width: 1px;
  background-color: #060606 !important;
}
#style-1::-webkit-scrollbar-track {
  background-color: transparent;
}

#style-1::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #7bc9ba;
}
.closed-popup {
  background: #7bc9ba;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 15px;
  text-align: center;
  border-radius: 20px;
  margin: auto;
  line-height: 25px;
  /* z-index: 2;
  position: sticky;
  top: 9px;
  float: right; */

  cursor: pointer;
}

/*  HOT EVENT */

hr.hr-border {
  border-color: #ffffff29;
}

.table > thead th {
  background-color: white !important;
  color: black;
}

.error-text {
  color: red;
  /* padding-left: 10px; */
}
.filter-box-new .btns-filter {
  display: flex;
}

.date-picker.bor .rmdp-input {
  border: 1px solid red;
}
.selected-datte {
  padding-left: 100px;
}
@media screen and (max-width: 1024px) {
  .event-sale-listing .event-sale-detail {
    max-height: 400px;
  }
  .hot-event-wrapper .output-card {
    width: 48%;
  }
  .sale-view-title {
    margin-bottom: 20px;
  }
  .sale-view-search-input input {
    padding: 0.5rem 0.5rem 0.5rem 4rem;
  }
  .sale-view-search-input .sale-search-icon {
    height: 38px;
    padding: 7px 15px;
  }
  .graphic .heading {
    font-size: 20px;
    line-height: normal;
  }
  .graphic p.recordss {
    padding: 0.3rem 1rem;
    font-size: 15px;
  }
  .enevt-cards-disc .cart {
    min-width: 48%;
    width: 48%;
  }
  .event-sale-listing .event-sale-detail {
    width: 95%;
    overflow: scroll;
  }
  .filter-by {
    min-width: 80px;
    font-size: 17px;
  }
}

@media screen and (max-width: 768px) {
  /* .filter-by-wrapper {
    margin-top: 25px;
  } */
  .search-output-cards .output-card {
    width: 80%;
  }
  .output-cart-content .title {
    font-size: 16px;
    line-height: normal;
  }
  .output-cart-content .weekname,
  .output-cart-content .address {
    font-size: 14px;
    line-height: normal;
  }
  .graphic {
    margin: 50px 0 0 0 !important;
  }
  section.sale-viewer {
    padding: 50px 0;
  }
  .enevt-cards-disc .cart {
    min-width: 30%;
    width: 30%;
  }
  .filter-box-new {
    flex-direction: column;
  }

  .filter-box-new .filter-by-wrapper {
    width: 100%;
  }
  .filter-box-new .btns-filter {
    margin-top: 15px;
    padding-left: 80px;
    justify-content: space-between;
  }
  .filter-box-new .btns-filter .search-button {
    width: 48%;

    margin-left: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .filter-box-new {
    flex-wrap: wrap;
  }
  .filter-box-new .filter-by-wrapper {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
  .filter-by {
    width: 100%;
  }
  .filter-box-new .search-button {
    margin-top: 10px;
    width: 45%;
    margin-left: 0 !important;
  }
  .filter-box-new .filter-by-wrapper select,
  .filter-box-new .filter-by-wrapper input {
    width: 48%;
    margin: 0;
  }
  .date-picker{
    width: 48%;
  }
  .filter-by-wrapper .date-picker .rmdp-container {
    width: 100% !important;
  }
  .date-picker .rmdp-container input{
    width: 100% !important;
  }

  .selected-datte,
  .filter-box-new .btns-filter {
    padding-left: 0;
  }
}
@media screen and (max-width: 500px) {
  .search-output-cards .output-card,
  .hot-event-wrapper .output-card {
    width: 100%;
  }
  .enevt-cards-disc .cart {
    min-width: 100%;
    width: 100%;
  }
  .graphic p.recordss {
    padding: 5px 8px;
    font-size: 12px;
  }
  .graphic .time-right {
    font-size: 12px;
  }
}
