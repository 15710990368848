.text-are {
  height: 250px;
  width: 100%;
  background: #1218238f;
  border: 1px solid #ffffff40;
  resize: none;
  color: #ffffffbd;
  padding: 1rem;
  border-radius: 9px;
}
textarea.text-are:focus {
  border: 1px solid #7bc9ba;
  box-shadow: none;
  outline: none;
}

.suggestion {
  text-align: left !important;
}

.add-to-toolkit {
  padding: 0.5rem 4rem;
  border: none;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border: 1px solid #7bc9ba;
  background-color: #121823;
  color: #7bc9ba;
  margin: auto;
}

.add-to-toolkit:hover {
  color: black;
  background-color: #7bc9ba;
}
.not-found {
  padding: 100px 0;
  text-align: center;
  margin: auto;
}
